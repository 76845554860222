import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/78a07ce7/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "to-center-left-or-right-align-text-use-these-classes"
    }}>{`To center, left or right align text use these classes:`}</h3>
    <p>{`To center it`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.center-text
`}</code></pre>
    <p>{`To align left`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.left-text
`}</code></pre>
    <p>{`To align right`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.right-text
`}</code></pre>
    <h3 {...{
      "id": "to-hide-elements"
    }}>{`To hide elements`}</h3>
    <p>{`To hide on tablet and down`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.hide-tablet-down
`}</code></pre>
    <p>{`To hide on mobile only`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.hide-mobile
`}</code></pre>
    <p>{`To hide on any screen size`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.hide
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      